import * as React from 'react'
import { Component } from 'react';
import { withRouter } from "gl-commonui";
import { RouteComponentProps } from 'react-router-dom';
import { NotificationFillPage } from './notificationfill';


@withRouter
export class NotificationEditPage extends Component<RouteComponentProps<any>> {
    render() {
        return <NotificationFillPage {...this.props} isEdit={true} />
    }
}